
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalBody extends Vue {

}
