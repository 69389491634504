
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }

})
export default class PortalMenu extends Vue {

    private isActive(route: string)
    {
        return route === this.$route.name;
    }

}
