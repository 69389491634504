
import { Options, Vue } from 'vue-class-component';

import PortalMenu from '@/components/Portal/PortalMenu.vue';
import PortalBody from '@/components/Portal/PortalBody.vue';

@Options({
  props: {
    msg: String
  },
  components: {
      PortalMenu, PortalBody
  }
})
export default class PortalTmplate extends Vue {

  private menuShow = false;
  private menuHidden = true;
  // private menuShow = true;
  // private menuHidden = false;


  mounted()
  {
      this.$watch('show', (show: boolean) => {
          if(show) this.menuHidden = false;
      });
  }

  private toggleMenu()
  {

    this.menuShow = !this.menuShow;
    this.menuHidden = !this.menuHidden;
  }

    public closeMenu()
    {
        this.menuHidden = true;

        setTimeout(() => {
          this.menuShow = false;
            this.$emit('close');
        }, 500);
    }

    private handleNav(anchor: string)
    {
        this.closeMenu()

        setTimeout(() => {
            this.$router.push({ name: anchor });
        }, 300);
    }

}
