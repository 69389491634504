<template>
    <div class="portal-menu">
        <ul class="portal-menu__menu">
            <li class="portal-menu__menu-item" :class="{ 'portal-menu__menu-item--active': isActive('portal-home')}"><span class="item"><router-link :to="{name: 'portal-home'}">Home</router-link></span><span class="marker"></span></li>
            <li class="portal-menu__menu-item" :class="{ 'portal-menu__menu-item--active': isActive('portal-players')}"><span class="item"><router-link :to="{name: 'portal-players'}">Players</router-link></span><span class="marker"></span></li>
            <li class="portal-menu__menu-item" :class="{ 'portal-menu__menu-item--active': isActive('portal-product')}"><span class="item"><router-link :to="{name: 'portal-product'}">Product</router-link></span><span class="marker"></span></li>
            <li class="portal-menu__menu-item" :class="{ 'portal-menu__menu-item--active': isActive('portal-organisations')}"><span class="item"><router-link :to="{name: 'portal-organisations'}">Organisations</router-link></span><span class="marker"></span></li>
            <li class="portal-menu__menu-item" :class="{ 'portal-menu__menu-item--active': isActive('portal-pgsolutions')}"><span class="item"><router-link :to="{name: 'portal-pgsolutions'}">PGSolutions</router-link></span><span class="marker"></span></li>
            <li class="portal-menu__menu-item" :class="{ 'portal-menu__menu-item--active': isActive('portal-thankyou')}"><span class="item"><router-link :to="{name: 'portal-thankyou'}">Thank You</router-link></span><span class="marker"></span></li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }

})
export default class PortalMenu extends Vue {

    private isActive(route: string)
    {
        return route === this.$route.name;
    }

}
</script>